<template>
  <div>
    <Subnavigation active="catering">
      <b-button @click="onOpenModal()">Vytvoriť novú akciu</b-button>
    </Subnavigation>
    <div class="container mt-5">
      <b-overlay :show="busySearch" rounded="lg">
        <div class="mb-md-4" :data-id="section.id" v-for="(section, i) in catering" :key="i">
          <b-card class="card" ref="card" :data-id="section.id" :class="{toggled: toggled[section.id]}">
            <div class="card-body-wrapper pb-3">
              <b-table v-table-sort="sortableOptions" striped :ref="'table' + section.id" hover :items="section.recipes" :fields="fields" class="daily-table" :tbody-tr-attr="addTrId" :data-id="section.id">
                <template #head(name)>
                  <span @click="toggleCard(i)" class="pointer-event">
                    {{section.name}}
                    <span class="date">{{ formatDay(section.date) }}</span>
                  </span>
                </template>

                <template #head(actions)>
                  <b-dropdown right class="policy-drop">
                    <template #button-content>
                      <span>...</span>
                    </template>
                    <b-dropdown-item @click.prevent="onOpenModal(section.id)">Upraviť akciu</b-dropdown-item>
                    <b-dropdown-item @click.prevent="onRemoveCatering(section.id)">Vymazať akciu</b-dropdown-item>
                    <b-dropdown-item @click.prevent="onRemoveAllRecipes(section.id)">Vymazať všetky recepty</b-dropdown-item>
                  </b-dropdown>
                </template>

                <template #cell(name)="value">
                  <div class="pointer-event" @click="onOpenRecipe(value.item.id)">{{value.value}}</div>
                </template>

                <template #cell(dragndrop)="value">
                  <span class="drop-index" :data-id="value.item.id"><DragNDrop/></span>
                </template>

                <template #cell(price_per_portion)="value">
                  <div>
                    {{ formatPrice(value.value) }} &euro;
                  </div>
                </template>

                <template #cell(portions)="value">
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button variant="tertiary" :disabled="value.item.added" @click="onChangePortions(+value.value - 1, section.id, value.item.id)"><svg width="7" height="1" viewBox="0 0 7 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="7" height="1" fill="#C4C4C4"/>
                      </svg></b-button>
                    </b-input-group-prepend>

                    <b-form-input type="number" :disabled="value.item.added" :value="value.value" debounce="500" @change="onChangePortions($event, section.id, value.item.id)" min="0" step="1"></b-form-input>

                    <b-input-group-append>
                      <b-button variant="tertiary" :disabled="value.item.added" @click="onChangePortions(+value.value + 1, section.id, value.item.id)"><svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="3" width="7" height="1" fill="#C4C4C4"/>
                        <rect x="3" y="7" width="7" height="1" transform="rotate(-90 3 7)" fill="#C4C4C4"/>
                      </svg></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </template>

                <template #cell(price_total)="value">
                  <div>
                    {{ formatPrice(value.value) }} &euro;
                  </div>
                </template>

                <template #cell(added)="value">
                  <template v-if="!demoDomain">
                    <b-button v-if="!value.value" @click="onAddToCart(value.item.id, section.id)">Do zoznamu</b-button>
                    <strong v-else>Pridané</strong>
                  </template>
                </template>

                <template #cell(actions)="value">
                  <template>
                    <b-dropdown right class="policy-drop">
                      <template #button-content>
                        <span>...</span>
                      </template>
                      <b-dropdown-item @click.prevent="onRemoveRecipe(section.id, value.item.id)">Vymazať</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </template>
              </b-table>
              <b-button block @click="navTo('/app/recipes')" class="add-recipe">+ Pridať ďalšie jedlo</b-button>
            </div>
          </b-card>
        </div>
        <b-card class="card" v-if="!catering.length">
          <NothingFound :btn-action="onOpenModal" btn-text="+ Vytvoriť novú akciu">
            <template slot="title">Nemáte pridanú akciu</template>
            <template slot="body">Aby ste mohli naplánovať receptúru do menu pre catering, musíte mať vytvorenú aspoň jednu udalosť. Pokračujte tlačidlom vytvoriť novú akciu.</template>
          </NothingFound>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {mapActions, mapGetters, mapMutations} from "vuex"
import DragNDrop from "@/plugins/app/_layouts/components/drag-n-drop"
import Subnavigation from "@/plugins/app/_layouts/components/subnavigation"
import CateringModal from '@/plugins/app/catering/catering-modal'
import NothingFound from "@/plugins/app/_layouts/components/nothing-found"
import format from "@/plugins/app/formatters"

export default Vue.extend({
  components: {Subnavigation, DragNDrop, NothingFound},
  props: ['vendorId'],
  data: function () {
    return {
      toggled: JSON.parse(localStorage.getItem('toggled_catering')) ?? [],
      sortableOptions: { onEnd: this.onDragEnd, handle: ".drop-index" },
      fields: [
        { key: 'dragndrop', label: ''},
        { key: 'name', label: ''},
        { key: 'portions', label: 'Počet porcií', class: 'toggled-hide'},
        { key: 'added', label: 'Možnosti', class: 'toggled-hide'},
        { key: 'actions', label: '...', class: 'toggled-hide'},
      ]
    }
  },
  computed: {
    ...mapGetters('catering', ['catering', 'busySearch']),
    demoDomain() {
      return process.env.VUE_APP_DEMO_DOMAIN === document.location.host
    },
  },
  mounted() {
    this.loadCatering()
  },
  methods: {
    ...mapActions('catering', [
      'addRecipeToCatering', 'removeRecipeFromCatering',
      'changePortionsOfRecipeInCatering', 'removeCatering',
      'removeAllRecipesFromCatering', 'loadCatering', 'changeSortingOfRecipesInCatering'
    ]),
    ...mapActions('venues', ['preloadVenues']),
    ...mapActions('cart', ['addToCart']),
    ...mapMutations('catering', ['updateCatering']),

    onDragEnd(event) {
      const newOrder = []
      for (const item of event.target.children) {
        newOrder.push(+item.dataset.id)
      }

      const id = +event.target.parentElement.dataset.id
      this.changeSortingOfRecipesInCatering({id, newOrder})
      this.updateCatering([])

    },

    onRemoveCatering(id) {
      this.removeCatering({id})
    },

    onRemoveRecipe(id, recipeId) {
      this.removeRecipeFromCatering({id, recipeId})
    },

    onRemoveAllRecipes(id) {
      this.removeAllRecipesFromCatering(id)
    },

    onOpenRecipe(id) {
      this.$router.push(`/app/recipes/${id}`)
    },

    onChangePortions(portions, id, recipeId) {
      this.changePortionsOfRecipeInCatering({portions, id, recipeId})
    },

    onOpenModal(cateringId) {
      this.$modal.show( CateringModal, {cateringId} )
    },

    async onAddToCart(recipe_id, catering_id) {
      await this.addToCart([{recipe_id, catering_id}])
      this.loadCatering()
    },

    addTrId(item) {
      return {
        'data-id': item.id
      }
    },

    navTo: function (to) {
      this.$router.push(to)
    },

    toggleCard(i) {
      const card = this.$refs.card[i]
      const id = card.dataset.id
      this.toggled[id] = !this.toggled[id]

      this.$refs.card[i].classList.toggle('toggled', this.toggled[id])
      localStorage.setItem('toggled_catering', JSON.stringify(this.toggled))
    },

    formatDay: format.day,
    formatNow: format.now,
    formatPrice: format.price,
  }
})
</script>
<style lang="scss">
.add-dropdown {
  width: 330px;
}
.add-recipe{
  background-color:#F6F6F6;
  color: #252525;
  border: none;
  font-weight: 500;
}
</style>
